// @keyframes marquee-fade {
//   0% {
//   }
//   20% {
//     mask: linear-gradient(
//       90deg,
//       rgba(0, 0, 0, 0) 0%,
//       rgba(0, 0, 0, 1) 4%,
//       rgba(0, 0, 0, 1) 96%,
//       rgba(0, 0, 0, 0) 100%
//     );
//     -webkit-mask: linear-gradient(
//       90deg,
//       rgba(0, 0, 0, 0) 0%,
//       rgba(0, 0, 0, 1) 4%,
//       rgba(0, 0, 0, 1) 96%,
//       rgba(0, 0, 0, 0) 100%
//     );
//     backdrop-filter: blur(10px);
//     -webkit-backdrop-filter: blur(10px);
//     transform: translateZ(0);
//     -webkit-transform: translateZ(0);
//     backface-visibility: hidden;
//     -webkit-backface-visibility: hidden;
//     perspective: 1000;
//     -webkit-perspective: 1000;
//   }
//   100% {
//     mask: linear-gradient(
//       90deg,
//       rgba(0, 0, 0, 0) 0%,
//       rgba(0, 0, 0, 1) 4%,
//       rgba(0, 0, 0, 1) 96%,
//       rgba(0, 0, 0, 0) 100%
//     );
//     -webkit-mask: linear-gradient(
//       90deg,
//       rgba(0, 0, 0, 0) 0%,
//       rgba(0, 0, 0, 1) 4%,
//       rgba(0, 0, 0, 1) 96%,
//       rgba(0, 0, 0, 0) 100%
//     );
//     backdrop-filter: blur(10px);
//     -webkit-backdrop-filter: blur(10px);
//     transform: translateZ(0);
//     -webkit-transform: translateZ(0);
//     backface-visibility: hidden;
//     -webkit-backface-visibility: hidden;
//     perspective: 1000;
//     -webkit-perspective: 1000;
//   }
// }

@keyframes marquee-span {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
