body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Azonix";
  src: local("Azonix"), url(./assets/fonts/azonix/azonix.otf) format("truetype");
}

@font-face {
  font-family: "SFProTextRegular";
  src: local("SFProTextRegular"),
    url(./assets/fonts/sfProText/sfProTextRegular.ttf) format("truetype");
}

@font-face {
  font-family: "SFProTextSemibold";
  src: local("SFProTextSemibold"),
    url(./assets/fonts/sfProText/sfProTextSemibold.otf) format("truetype");
}

@font-face {
  font-family: "SFProTextBold";
  src: local("SFProTextBold"),
    url(./assets/fonts/sfProText/sfProTextBold.ttf) format("truetype");
}

@font-face {
  font-family: "SFMonoRegular";
  src: local("SFMonoRegular"),
    url(./assets/fonts/sfMono/sfMonoRegular.otf) format("truetype");
}

@font-face {
  font-family: "SFMonoSemibold";
  src: local("SFMonoSemibold"),
    url(./assets/fonts/sfMono/sfMonoSemibold.otf) format("truetype");
}

@font-face {
  font-family: "GothamBlack";
  src: local("GothamBlack"),
    url(./assets/fonts/gotham/gothamBlack.otf) format("truetype");
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.noScrollIndicator {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-button {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  &:active {
    span {
      opacity: 0.5;
    }
  }
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
