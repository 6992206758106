#loading {
  animation: loadingSpin 1000ms linear infinite;
  #pulse {
    animation: loadingScale 1000ms ease-in-out infinite;
  }
  // ,
  //   loadingScale 1000ms ease infinite;
}

@keyframes loadingSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingScale {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.6, 1.6);
  }
  100% {
    transform: scale(1, 1);
  }
}
