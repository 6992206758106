$thumb-size: 10px;
$inactive-color: #ffffff80;
$active-color: #ffffff;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;

  &:active {
    cursor: grabbing;
  }
}

.song-page-slider {
  position: relative;
  display: flex;
  align-items: center;
  height: $thumb-size;
  width: 100%;
}

.input-wrapper {
  width: calc(100% + #{$thumb-size});
  margin: 0 calc(#{$thumb-size} / -2);
  position: absolute;
  height: $thumb-size;
}

.control-wrapper {
  width: 100%;
  position: absolute;
  height: $thumb-size;
}

input {
  position: absolute;
  width: 100%;
  //   pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
}

.rail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 5px;
  border-radius: 99px;
  overflow: hidden;
  background: $inactive-color;
}

.inner-rail {
  position: absolute;
  height: 100%;
  background: $active-color;
  opacity: 0.9;
}

.thumb {
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 50%;
  position: absolute;
  background: $active-color;
  top: 50%;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
}
